import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TokenService } from '../services/token/token.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private static readonly LOGIN_PATH = '/login';
  private static readonly F726564_PATH = '/f726564'; // f in hex
  private static readonly UNAUTHORIZED_CALLS: string[] = [
    ApiInterceptor.LOGIN_PATH,
    '/assets/i18n/*',
  ];

  constructor(
    private readonly router: Router,
    private readonly tokenService: TokenService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    request = this.addBearerTokenToRequestHeaders(request);
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          return event;
        },
        (err: any) => {
          if (err.status === 401) {
            this.tokenService.removeToken();
            void this.router.navigateByUrl(ApiInterceptor.LOGIN_PATH).then();
          } else if (err.status === 403) {
            void this.router.navigateByUrl(ApiInterceptor.F726564_PATH).then();
          }
        },
      ),
    );
  }

  private addBearerTokenToRequestHeaders(
    request: HttpRequest<any>,
  ): HttpRequest<any> {
    // When it's an unauthorized call we do not set the token
    if (
      !request.url ||
      ApiInterceptor.UNAUTHORIZED_CALLS.some((call) =>
        new RegExp(call).test(request.url),
      )
    ) {
      return request;
    }

    const bearerToken = this.tokenService.getBearerToken();
    if (!bearerToken) {
      void this.router.navigateByUrl(ApiInterceptor.LOGIN_PATH).then();
    }

    return request.clone({
      setHeaders: { Authorization: `Bearer ${bearerToken}` },
    });
  }
}
