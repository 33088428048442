import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/shared/utils/definitions';

@Injectable({ providedIn: 'root' })
export class TokenService {
  getBearerToken(): string | null {
    return window.localStorage.getItem(AppSettings.LOCAL_STORAGE_TOKEN_KEY);
  }

  setBearerToken(token: string): void {
    window.localStorage.setItem(AppSettings.LOCAL_STORAGE_TOKEN_KEY, token);
  }

  removeToken(): void {
    window.localStorage.removeItem(AppSettings.LOCAL_STORAGE_TOKEN_KEY);
  }
}
