import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { catchError, OperatorFunction, throwError } from 'rxjs';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function catchSomethingWrong<T>(): OperatorFunction<T, T> {
  return catchError(() => {
    return throwError(() => 'COMMON.ERRORS.something.wrong');
  });
}
