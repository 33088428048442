// Global event emitter keys
export const USER_LOGIN_STATE_CHANGE_EMITTER = 'userLoginStateChange';
export const SIDEBAR_TOGGLED_EMITTER = 'sidebarToggledEmitter';
export const WARNINGS_CHANGED_EMITTER = 'warningsChangedEmitter';

export interface DateRange { start: Date; end: Date }

export class AppSettings {
  // local storage consts
  public static LOCAL_STORAGE_TOKEN_KEY = 'userToken';
  public static LOCAL_STORAGE_LOCALE = 'locale';
  public static LIST_SIZE = 20;
}
