import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { TokenService } from './token/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private readonly router: Router,
    private readonly tokenService: TokenService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    // check if we already have a token set
    const bearerToken = this.tokenService.getBearerToken();
    if (!bearerToken) {
      void this.router
        .navigate(['/login'], { queryParams: { 'return-uri': state.url } })
        .then();
      return false;
    }
    // redirect if needed
    const uri = route.data['redirectUri'];
    if (uri) {
      void this.router.navigateByUrl(uri).then();
    }
    return true;
  }
}
